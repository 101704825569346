import React from 'react';
import {useSelector} from 'react-redux';
import {
    selectFeatures,
    selectPermissions,
    selectUser,
    selectVSAccountFeatures,
    selectVSFeatures
} from '@components/Login/userSlice';
import SubMenu from './SubMenu';
import {getAvailableAccountRoutes} from '../../../../../utils/routes';
import {
    ACCOUNT_GROUPS_HEADINGS,
    ACCOUNT_PATH
} from '../../../../../../constants';

export default function AccountSubMenu({isOpen, onClick}) {
    const user = useSelector(selectUser);
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const vsFeatures = useSelector(selectVSFeatures);
    const vsAccountFeatures = useSelector(selectVSAccountFeatures);
    const enabledRoutes = getAvailableAccountRoutes({
        user,
        features,
        permissions,
        vsFeatures,
        vsAccountFeatures
    });

    return (
        <SubMenu
            isOpen={isOpen}
            onClick={onClick}
            routes={enabledRoutes}
            basePath={ACCOUNT_PATH}
            headings={Object.values(ACCOUNT_GROUPS_HEADINGS)}
            type="account"
        />
    );
}
