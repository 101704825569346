import {Routes, Route} from 'react-router-dom';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {IntlProvider} from 'react-intl';
// TODO: get rid of
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createMuiTheme} from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import DOMPurify from 'isomorphic-dompurify';
import {initPDFJS} from './shared/Documents/utils';
import {useSenderTheme} from '../theme/senderTheme';
import Layout from './common/Layout/Layout';
import LayoutV2 from './common/LayoutV2/Layout';
import ErrorPage from './common/ErrorPage/ErrorPage';
import {BroadcastStation} from './BroadcastStation';
import {
    selectTranslations, selectLocale
} from './appSlice';
import {
    selectFeatures, selectHasUserPermissionOnRoot, selectPermissions, selectUser
} from './Login/userSlice';
import PrivateRoute from './PrivateRoute';
import useUnauthInterceptor from '../hooks/useUnauthInterceptor';
import {getAvailableMainRoutes} from '../utils/routes';
import {capitalizeFirstLetter} from '../utils/helpers';
import {VS_FEATURES} from '../../constants';
import VSFeature, {Disabled, Enabled} from './shared/VSFeature/VSFeature';
import './shared/app.less';
import './shared/notifications.less';
import '@ValidSignBV/vs-ui/dist/style.css'; // TODO: Figure it out why styles are not embaded into components

DOMPurify.setConfig({
    USE_PROFILES: {html: true},
    IN_PLACE: true,
    ADD_ATTR: ['target']
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    const target = node.getAttribute('target');

    if (typeof (target) === 'string' && target.toLowerCase() === '_blank') {
        node.setAttribute('rel', 'noopener noreferrer');
    } else {
        node.removeAttribute('target');
    }
});
initPDFJS();

function App() {
    const translations = useSelector(selectTranslations);
    const locale = useSelector(selectLocale);
    const user = useSelector(selectUser);
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const enabledRoutes = getAvailableMainRoutes({
        user, features, permissions, hasUserPermissionOnRoot
    });

    moment.locale(locale);

    if (locale === 'nl') {
        moment.updateLocale('nl', {
            monthsShort: moment.monthsShort('-MMM-').map(capitalizeFirstLetter),
            months: moment.months().map(capitalizeFirstLetter)
        });
    }

    useUnauthInterceptor();
    useSenderTheme();

    const outerTheme = createMuiTheme({
        typography: {
            fontFamily: '"Segoe UI", sans-serif'
        },
        palette: {
            optionalSignatureButton: null,
            signatureButton: null
        },
        props: {
            // TODO: https://github.com/mui/material-ui/issues/30953
            MuiButtonBase: {disableRipple: true},
            MuiTextField: {
                variant: 'outlined'
            },
            MuiFormControl: {
                variant: 'outlined'
            },
            MuiSelect: {
                variant: 'outlined'
            },
            MuiOutlinedInput: {
                padding: '11px'
            }
        }
    });

    const routes = (
        <Routes>
            {enabledRoutes.map(({component: Component, childrenRoutes = [], ...route}) => (
                <Route
                    key={`route-${route.path}`}
                    path={route.path}
                    element={(
                        <PrivateRoute {...route}>
                            <Component />
                        </PrivateRoute>
                    )}
                >
                    {childrenRoutes?.map(({component: NestedComponent, ...rest}) => (
                        <Route
                            key={`childRoute-${rest.path}`}
                            element={(<NestedComponent />)}
                            {...rest}
                        />
                    ))}
                </Route>
            ))}
            <Route component={ErrorPage} />
        </Routes>
    );

    return (
        <IntlProvider locale={locale} messages={translations}>
            <ThemeProvider theme={outerTheme}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                    <BroadcastStation>
                        <VSFeature feature={VS_FEATURES.DESIGN_V2}>
                            <Enabled>
                                <LayoutV2>
                                    {routes}
                                </LayoutV2>
                            </Enabled>
                            <Disabled>
                                <Layout>
                                    {routes}
                                </Layout>
                            </Disabled>
                        </VSFeature>
                    </BroadcastStation>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </IntlProvider>
    );
}

export default App;
