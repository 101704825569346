import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import './subMenu.less';

export default function SubMenu({
    isOpen, onClick, routes, basePath, headings = [], type = ''
}) {
    const intl = useIntl();
    const location = useLocation();
    const outerRoutes = routes.filter(({isInnerPage}) => !isInnerPage);

    function getMenuItemClassName(path) {
        const isActive = location.pathname.endsWith(`${basePath}/${path}`);

        return classnames('menu-sub-item', {
            'is-active': isActive
        });
    }

    function getGroupRoutes(groupHeading) {
        return outerRoutes.filter(({heading}) => heading === groupHeading);
    }
    function getSublinkAttr() {
        switch (type) {
            case 'account':
                return 'test-header-account-menu-sublink';
            case 'admin':
                return 'test-header-admin-menu-sublink';
            default:
                return '';
        }
    }

    function renderGroup(heading) {
        const groupRoutes = getGroupRoutes(heading);

        if (isEmpty(groupRoutes)) {
            return null;
        }

        return (
            <React.Fragment key={heading}>
                <ListSubheader classes={{root: 'menu-sub-heading'}} disableSticky>
                    {intl.formatMessage({id: heading, defaultMessage: ''})}
                </ListSubheader>
                {groupRoutes.map(({
                    path, query, titleKey
                }) => (
                    <MenuItem
                        classes={{root: getMenuItemClassName(path)}}
                        key={path}
                        data-wdio={getSublinkAttr()}
                    >
                        <Link
                            to={`${basePath}/${path}${query ? `?${query}` : ''}`}
                            className="sub-link"
                            onClick={onClick}
                        >
                            {intl.formatMessage({id: titleKey, defaultMessage: ''})}
                        </Link>
                    </MenuItem>
                ))}
            </React.Fragment>
        );
    }

    return (
        <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
        >
            {headings.map(renderGroup)}
        </Collapse>
    );
}
