export function getAppVariables() {
    const commonVars = {
        'designer-field-panel-recipients-height': '88px',
        'designer-left-panel-width': '316px',
        'designer-right-panel-width': '300px',
        'designer-navigation-width': '65px',
        'designer-mobile-header-height': '46px',
        'designer-mobile-navigation-height': '50px',
        'designer-mobile-fields-height': '120px',
        'documents-carousel-container-height': '160px',
        'document-name-container-height': '24px',
        'signers-bar-height': '88px',
        'sent-to-sign-desktop-height': '48px'
    };
    const light = {
        ...commonVars,
        'designer-right-panel-background-color': 'var(--background-color-accented)',
        'designer-title-background-color': 'var(--background-color-light)',
        'designer-recipient-avatar-color': 'var(--brand-color)',
        'designer-recipient-avatar-background-color': 'rgba(47, 80, 123, 0.5)',
        'designer-left-panel-background-color': 'transparent'
    };
    const dark = {
        ...commonVars,
        'designer-right-panel-background-color': 'var(--background-color-accented)',
        'designer-title-background-color': 'var(--background-color-accented)',
        'designer-recipient-avatar-color': 'var(--brand-color)',
        'designer-recipient-avatar-background-color': 'rgba(47, 80, 123, 0.5)',
        'designer-left-panel-background-color': 'transparent'
    };
    const lightContrast = {
        ...light,
        ...commonVars
    };

    return {
        light,
        dark,
        lightContrast
    };
}
