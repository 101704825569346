import React from 'react';
import {useSelector} from 'react-redux';
import SubMenu from './SubMenu';
import {
    selectFeatures, selectHasUserPermissionOnRoot, selectPermissions, selectUser
} from '../../../../Login/userSlice';
import {getAvailableAdminRoutes} from '../../../../../utils/routes';
import {
    ADMIN_GROUPS_HEADINGS,
    ADMIN_PATH
} from '../../../../../../constants';
import './subMenu.less';

export default function AdminSubMenu({isOpen, onClick}) {
    const user = useSelector(selectUser);
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const enabledRoutes = getAvailableAdminRoutes({
        user, features, permissions, hasUserPermissionOnRoot, skipIndex: true
    });

    return (
        <SubMenu
            isOpen={isOpen}
            onClick={onClick}
            routes={enabledRoutes}
            basePath={ADMIN_PATH}
            headings={Object.values(ADMIN_GROUPS_HEADINGS)}
            type="admin"
        />
    );
}
