import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import HeaderV2 from '../HeaderV2/HeaderV2';
import GDPRBunner from '../GDPRBunner/GDPRBunner';
import {
    DASHBOARD_PATH,
    LOGIN_PATH,
    PASSWORD_RECOVERY_PATH,
    DESIGNER_PATH,
    PASSWORD_RESET_PATH,
    REGISTRATION_PATH,
    ERROR_PATH,
    FAST_TRACK_PATH
} from '../../../../constants';
import {selectLocale} from '../../appSlice';
import useAccessRestrictions from '../../../hooks/useAccessRestrictions';
import './layout.less';

export default function Layout({children}) {
    const intl = useIntl();
    const location = useLocation();
    const locale = useSelector(selectLocale);
    const {hideHeader} = useAccessRestrictions();
    const shouldShowCurtain = location.pathname.startsWith(DASHBOARD_PATH);
    const className = classNames('validsign');
    const curtainClassName = classNames('curtain', {
        'should-show-curtain': shouldShowCurtain
    });
    const shouldShowHeader = !location.pathname.startsWith(LOGIN_PATH)
        && !location.pathname.startsWith(PASSWORD_RECOVERY_PATH)
        && !location.pathname.startsWith(PASSWORD_RESET_PATH)
        && !location.pathname.startsWith(REGISTRATION_PATH)
        && !location.pathname.startsWith(ERROR_PATH)
        && !location.pathname.startsWith(FAST_TRACK_PATH)
        && !location.pathname?.includes(DESIGNER_PATH)
        && !hideHeader;

    return (
        <div className={className}>
            <Helmet htmlAttributes={{lang: locale}}>
                <title>
                    {intl.formatMessage({id: 'page.title', defaultMessage: ''})}
                </title>
            </Helmet>
            <GDPRBunner />
            <div className={curtainClassName} />
            {shouldShowHeader && <HeaderV2 />}
            <section className="main">
                {children}
            </section>
        </div>
    );
}
