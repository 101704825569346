import './utils/nonce';
import {hydrateRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {loadableReady} from '@loadable/component';
import {Provider} from 'react-redux';
import useInitializeGraphicalSignature from '@components/SignerUi/hooks/useInitializeGraphicalSignature';
import getStore from './setup/store';
import NotificationsHub from './components/shared/NotificationsHub/NotificationsHub';
import App from './components/App';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-getcanonicallocales/polyfill';
import {BASE_PATH} from '../constants';

/* eslint-disable react/jsx-filename-extension */
const Client = () => {
    const store = getStore();
    useInitializeGraphicalSignature();
    return (
        <Provider store={store} key="provider">
            <Router basename={BASE_PATH}>
                <NotificationsHub />
                <App />
            </Router>
        </Provider>
    );
};

window.onload = () => {
    loadableReady(() => {
        hydrateRoot(
            document.getElementById('app'),
            <Client />
        );
    });
};
