import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {HiOutlineUserCircle} from '@react-icons/all-files/hi/HiOutlineUserCircle';
import {GiFat} from '@react-icons/all-files/gi/GiFat';
import {HiOutlineChevronDown} from '@react-icons/all-files/hi/HiOutlineChevronDown';
import {HiOutlineChevronUp} from '@react-icons/all-files/hi/HiOutlineChevronUp';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import AdminSubMenu from './SubMenus/AdminSubMenu';
import AccountSubMenu from './SubMenus/AccountSubMenu';
import {startIntroduction} from '../../../AccountSettings/accountSettingsSlice';
import ConfirmationModal from '../../../shared/ConfirmationModal/ConfirmationModal';
import {
    selectUser, logout, selectFeatures, selectPermissions, selectHasUserPermissionOnRoot
} from '../../../Login/userSlice';
import {getAvailableAdminRoutes} from '../../../../utils/routes';
import {
    ACCOUNT_PATH, ADMIN_PATH, DASHBOARD_PATH, LOGOUT_PATH
} from '../../../../../constants';
import './userMenu.less';

export default function UserMenu() {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const enabledRoutes = getAvailableAdminRoutes({
        user, features, permissions, hasUserPermissionOnRoot
    });
    const isFatIcons = get(user, 'data.fatIcons');
    const avatarSrc = get(user, 'data.avatar', '');
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const isMenuOpen = !!anchorEl;
    const menuId = 'user-menu';
    const accountIcon = !isFatIcons ? <HiOutlineUserCircle aria-hidden /> : <GiFat aria-hidden />;
    const showIntro = get(user, 'data.showIntro');

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
        setIsAdminMenuOpen(false);
        setIsAccountMenuOpen(false);
    }

    async function onLogOut() {
        const logoutUrl = await dispatch(logout());
        window.location.href = logoutUrl || LOGOUT_PATH;
    }

    function getIsActiveClass(route) {
        return location.pathname.startsWith(route) ? 'is-active' : '';
    }

    function toggleAdminMenu() {
        setIsAdminMenuOpen(!isAdminMenuOpen);
        setIsAccountMenuOpen(false);
    }

    function toggleAccountMenu() {
        setIsAccountMenuOpen(!isAccountMenuOpen);
        setIsAdminMenuOpen(false);
    }

    function userInfoItem() {
        return (
            <MenuItem
                divider
                disabled
                classes={{root: 'menu-item user-info-item'}}
            >
                <div className="user-menu-info-item">
                    <div className="icon-container">
                        <Avatar
                            classes={{root: 'user-menu-item-avatar'}}
                            alt={`${user.firstName} ${user.lastName}`}
                            src={avatarSrc}
                        >
                            {user.firstName.substr(0, 1)}
                        </Avatar>
                    </div>
                    <div className="info-container">
                        <div className="full-name">
                            {`${user.firstName} ${user.lastName}`}
                        </div>
                        <div className="email">
                            <small>
                                {user.email}
                            </small>
                        </div>
                        {user.type && (
                            <div className="type">
                                {intl.formatMessage({id: `esl.account.${user.type.toLowerCase()}`, defaultMessage: ''})}
                            </div>
                        )}
                    </div>
                </div>
            </MenuItem>
        );
    }

    function goToPage(link) {
        navigate(link);
        handleMenuClose();
    }

    async function onWalkthrough() {
        const wasSuccessful = await dispatch(startIntroduction());
        if (wasSuccessful) {
            if (showIntro && location.pathname === DASHBOARD_PATH) {
                window.location.reload(false);
            }
            goToPage(DASHBOARD_PATH);
        }
    }

    function renderMenu() {
        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                id={menuId}
                keepMounted
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={isMenuOpen}
                onClose={handleMenuClose}
                variant="menu"
                MenuListProps={{
                    disablePadding: true,
                    classes: {root: 'user-menu-list'}
                }}
            >
                {userInfoItem()}
                <MenuItem
                    classes={{root: `menu-item sub-menu-header link ${getIsActiveClass(ACCOUNT_PATH)}`}}
                    selected={location.pathname.startsWith(ACCOUNT_PATH)}
                >
                    <ListItemText
                        onClick={() => goToPage(ACCOUNT_PATH)}
                        primary={intl.formatMessage({id: 'esl.generic.my_account', defaultMessage: ''})}
                        classes={{root: 'menu-item-title'}}
                        data-wdio="test-header-menu-account-item"
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            aria-label={intl.formatMessage({id: 'esl.generic.expand', defaultMessage: ''})}
                            onClick={toggleAccountMenu}
                            size="small"
                            data-wdio="test-header-expand-menu-account-items"
                        >
                            {isAccountMenuOpen
                                ? <HiOutlineChevronUp className="chevron-icon" />
                                : <HiOutlineChevronDown className="chevron-icon" />}
                        </IconButton>
                    </ListItemSecondaryAction>
                </MenuItem>
                <AccountSubMenu isOpen={isAccountMenuOpen} onClick={handleMenuClose} />
                {!isEmpty(enabledRoutes) && (
                    <MenuItem
                        classes={{root: `menu-item sub-menu-header link ${getIsActiveClass(ADMIN_PATH)}`}}
                    >
                        <ListItemText
                            onClick={() => goToPage(ADMIN_PATH)}
                            primary={intl.formatMessage({id: 'esl.generic.admin', defaultMessage: ''})}
                            classes={{root: 'menu-item-title'}}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                aria-label={intl.formatMessage({id: 'esl.generic.expand', defaultMessage: ''})}
                                onClick={toggleAdminMenu}
                                size="small"
                                data-wdio="test-header-expand-menu-admin-items"

                            >
                                {isAdminMenuOpen
                                    ? <HiOutlineChevronUp className="chevron-icon" />
                                    : <HiOutlineChevronDown className="chevron-icon" />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </MenuItem>
                )}
                {!isEmpty(enabledRoutes) && <AdminSubMenu isOpen={isAdminMenuOpen} onClick={handleMenuClose} />}
                <MenuItem
                    classes={{root: 'menu-item link'}}
                    onClick={() => setIsConfirmationModalOpen(true)}
                >
                    <span className="menu-item-text">
                        {intl.formatMessage({id: 'esl.generic.walkthrough', defaultMessage: ''})}
                    </span>
                </MenuItem>
                <MenuItem classes={{root: 'menu-item link'}} onClick={onLogOut}>
                    <span className="link">
                        {intl.formatMessage({id: 'esl.generic.logout', defaultMessage: ''})}
                    </span>
                </MenuItem>
            </Menu>
        );
    }

    if (isEmpty(user)) {
        return null;
    }

    return (
        <div className="user-menu">
            <div className="user-menu-container">
                <Button
                    aria-label={intl.formatMessage({id: 'esl.account.menu.label', defaultMessage: ''})}
                    aria-controls={menuId}
                    aria-haspopup="true"
                    classes={{root: 'user-menu-button'}}
                    onClick={handleMenuOpen}
                    data-wdio="test-header-menu-icon-btn"
                >
                    {avatarSrc ? (
                        <Avatar
                            classes={{root: 'user-menu-avatar'}}
                            alt={`${user.firstName} ${user.lastName}`}
                            src={avatarSrc}
                        />
                    ) : accountIcon}
                </Button>
            </div>
            {renderMenu()}
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                descriptionKeys={[showIntro ? 'page.dashboard.intro.restartMessage' : 'page.dashboard.intro.startMessage']}
                setIsOpen={setIsConfirmationModalOpen}
                onConfirm={onWalkthrough}
            />
        </div>
    );
}
