import React from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import Header from '../Header/Header';
import GDPRBunner from '../GDPRBunner/GDPRBunner';
import {
    LOGIN_PATH,
    PASSWORD_RECOVERY_PATH,
    DESIGNER_PATH,
    PASSWORD_RESET_PATH,
    REGISTRATION_PATH,
    VS_FEATURES,
    ERROR_PATH,
    FAST_TRACK_PATH
} from '../../../../constants';
import {selectIsGlobalLoading, selectLocale} from '../../appSlice';
import useAccessRestrictions from '../../../hooks/useAccessRestrictions';
import FloatingActions from '../../shared/FloatingActions/FloatingActions';
import VSFeature, {Enabled} from '../../shared/VSFeature/VSFeature';
import Loading from '../../shared/Loading/Loading';
import './layout.less';

export default function Layout({children}) {
    const intl = useIntl();
    const location = useLocation();
    const locale = useSelector(selectLocale);
    const isGlobalLoading = useSelector(selectIsGlobalLoading);
    const {hideHeader} = useAccessRestrictions();
    const className = classNames('validsign');
    const shouldShowHeader = !location.pathname.startsWith(LOGIN_PATH)
        && !location.pathname.startsWith(PASSWORD_RECOVERY_PATH)
        && !location.pathname.startsWith(PASSWORD_RESET_PATH)
        && !location.pathname.startsWith(REGISTRATION_PATH)
        && !location.pathname.startsWith(ERROR_PATH)
        && !location.pathname.startsWith(FAST_TRACK_PATH)
        && !location.pathname?.includes(DESIGNER_PATH)
        && !hideHeader;

    return (
        <div className={className}>
            <Loading isLoading={isGlobalLoading} />
            <Helmet htmlAttributes={{lang: locale}}>
                <title>
                    {intl.formatMessage({id: 'page.title', defaultMessage: ''})}
                </title>
            </Helmet>
            <GDPRBunner />
            {shouldShowHeader && <Header />}
            <section className="main">
                {children}
            </section>
            <VSFeature feature={VS_FEATURES.FLOATING_MENU}>
                <Enabled>
                    <FloatingActions />
                </Enabled>
            </VSFeature>
        </div>
    );
}
