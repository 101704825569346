import {useValidSignThemeAsRoot, getValidSignThemeRootString} from '@ValidSignBV/theme-switcher';
import {selectTheme} from '@components/appSlice';
import {useSelector} from 'react-redux';
import {THEMES} from '../../../constants';
import {getAppVariables} from './appCssVars';

export const useSenderTheme = () => {
    const theme = useSelector(selectTheme) || THEMES.LIGHT;
    useValidSignThemeAsRoot({theme, styleTagId: 'sender-ui-css-vars', hostAppVariables: getAppVariables()});
    return null;
};

export const getSenderThemeAsString = ({theme}) => getValidSignThemeRootString({theme, hostAppVariables: getAppVariables()});
